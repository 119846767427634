import styled, { createGlobalStyle } from "styled-components"

import bg from "../images/ricepaper2.png"

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
    font-family: 'Nanum Myeongjo', serif;
  }

  body {
    margin: 0;
    color: black;
    background-image: url('${bg}');
  }
`

export const Grid = styled.div`
  width: 100vw;
  height: 100vh;
`
