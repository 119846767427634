import React from "react"
import { Helmet } from "react-helmet"

import { GlobalStyles, Grid } from "./Layout.style"

type Props = {
  children: React.ReactNode
  title: string
}

export const Layout: React.FC<Props> = ({ children, title }) => (
  <>
    <Helmet>
      <title>{title}</title>
      <link rel="preconnect" href="https://fonts.gstatic.com" />
      <link
        href="https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=block"
        rel="stylesheet"
      />
    </Helmet>
    <GlobalStyles />

    <Grid>{children}</Grid>
  </>
)
